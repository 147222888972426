<template>
  <v-dialog
    :value="show"
    no-click-animation
    persistent
    fullscreen
    scrollable
  >
    <v-card>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold" v-if="!data.id">Add New Ingredient</span>
        <span class="font-weight-bold" v-if="data.id">You are updating Ingredient <span class="primary--text">"{{ data['Name'] }}"</span> </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('cancel')" absolute>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="ingredientForm">
          <v-row>
            <v-col>
              <v-text-field
                v-model="data['Name']"
                :rules="[rules.required]"
                placeholder="Food Name"
                outlined
              ></v-text-field>
            </v-col>

            <v-col>
              <v-select
                v-model="data.category"
                placeholder="Category"
                :items="orderedCategories"
                item-text="name"
                item-value="id"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" v-for="(nutrient, i) in nutrients" :key="i">
              <v-text-field
                v-model="data[nutrient]"
                :label="formatLabel(nutrient)"
                :value="data[nutrient]"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <!-- ACTION BUTTONS -->
        <div class="mt-7 d-flex gap-7 align-center">
          <v-btn
            v-if="!data.id"
            @click="validateForm()"
            :loading="status.adding"
            color="accent"
            depressed
            dark
          >
            Add
          </v-btn>

          <v-btn
            v-if="data.id"
            @click="validateForm()"
            :loading="status.updating"
            color="accent"
            depressed
            dark
          >
            Update
          </v-btn>

          <v-btn
            @click="$emit('cancel')"
            :disabled="status.adding || status.updating"
            depressed
          >Close</v-btn>

          <span class="error--text">{{ this.error }}</span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { measures, nutrients } from '@/options'
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import _ from 'lodash'

export default {
  name: 'AddIngredient',

  props: {
    show: Boolean,
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      nutrients,
      measures,
      measureName: null,
      error: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.ingredients.data,
      status: state => state.ingredients.status,
      categories: state => state.categories.categories,
    }),

    orderedCategories: function() {
      return _.orderBy(this.categories, ['name'], ['asc'])
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('ingredients', [
      'addMeasure',
      'addIngredient',
      'updateIngredient',
    ]),

    validateMeasureForm() {
      if (this.$refs.measureForm.validate()) {
        this.addMeasure(this.measureName)
      }
    },

    validateForm() {
      this.error = null

      if (this.$refs.ingredientForm.validate()) {
        if (this.data.id) {
          Promise.all([this.updateIngredient()])
          .then(() => {
            this.$emit('cancel')
          })
        }
        else {
          Promise.all([this.addIngredient()])
          .then(() => {
            this.$emit('cancel')
          })
        }
      }
      else {
        this.error = 'Please check for any error.'
      }
    },

    formatLabel(label) {
      label = _.startCase(label)
      return label
    }
  },
}

</script>
