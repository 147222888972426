<template>

  <div class="full-height">
    <v-card class="mx-auto" ref="ingredientCard" :loading="status.searching">
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Ingredients</span>

        <v-sheet color="transparent" width="100%" max-width="600">
          <v-text-field
            v-model="search.term"
            @keyup.enter="searchIngredient()"
            @click:clear="$store.commit('ingredients/clearSearch')"
            placeholder="Search ingredients"
            append-icon="mdi-magnify"
            style="max-width: 600px"
            class="mr-5"
            hide-details
            clearable
            rounded
            dense
            solo
          ></v-text-field>
        </v-sheet>

        <div>
          <v-chip color="warning" small class="mr-2" v-if="pendings.length">{{ pendings.length }}</v-chip>

          <v-btn @click="addRecipeDialog()" color="primary" class="mr-2" small>Add New</v-btn>

          <v-menu bottom left offset-y transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item dense @click="addRecipeDialog()">
                <v-list-item-icon class="mr-2"><v-icon small>mdi-plus-circle</v-icon></v-list-item-icon>
                <v-list-item-title>Add</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="setStatus('active')">
                <v-list-item-icon class="mr-2"><v-icon small>mdi-check-circle</v-icon></v-list-item-icon>
                <v-list-item-title>Active</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="setStatus('pending')">
                <v-list-item-icon class="mr-2"><v-icon small>mdi-alert-circle</v-icon></v-list-item-icon>
                <v-list-item-title>Pending</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="refreshList()">
                <v-list-item-icon class="mr-2"><v-icon small>mdi-refresh</v-icon></v-list-item-icon>
                <v-list-item-title>Refresh List</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>
      <v-simple-table
        v-if="ingredients.length && !status.getting"
        fixed-header
        dense
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th v-if="$store.state.user.user && $store.state.user.user.role == 'admin'"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ingredient in ingredients" :key="ingredient.id" style="vertical-align: middle" :class="{ 'active' : toEdit == ingredient }">
              <td class="blue-grey--text">
                <circular v-if="status.approving.includes(ingredient.id)" />
                {{ ingredient['Name'] }}
              </td>

              <!-- <td class="greytext">{{ $store.getters['categories/categoryName'](ingredient.category) }}</td> -->

              <td v-if="ingredient.status == 'pending' || ingredient.status == 'disapproved'">
                <v-chip label small class="mr-2" color="warning" v-if="ingredient.status == 'pending'">pending approval</v-chip>
                <v-chip label small class="mr-2" color="red" v-if="ingredient.status == 'disapproved'" outlined>disapproved</v-chip>
                
                <span v-if="user && (user.role !== 'dietitian' || user.role !== 'nutritionist' || user.role !== 'naturopath')" class="mr-2">by {{ $store.getters['members/getUser'](ingredient.user) }}</span>
                <span  v-if="ingredient.status !== 'disapproved'" class="caption grey--text">{{ ingredient.dateAdded | fromNow }}</span>
              </td>

              <td v-else-if="ingredient.status == 'active'">
                <span class="success--text">active</span>
              </td>

              <td width="10" v-if="$store.state.user.user && $store.state.user.user.role == 'admin'">
                <v-menu bottom left offset-y transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      small
                    >
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list v-if="ingredient.status == 'active'">
                    <v-list-item dense @click="setToEdit(ingredient)">
                      <v-list-item-icon class="mr-2"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item dense @click="setToDelete(ingredient)">
                      <v-list-item-icon class="mr-2"><v-icon color="red" small>mdi-delete</v-icon></v-list-item-icon>
                      <v-list-item-title> <span class="red--text">Delete</span> </v-list-item-title>
                    </v-list-item>
                  </v-list>

                  <v-list v-if="ingredient.status == 'pending'">
                    <v-list-item dense @click="approveIngredient(ingredient)">
                      <v-list-item-icon class="mr-2"><v-icon small>mdi-check-circle</v-icon></v-list-item-icon>
                      <v-list-item-title>Approve</v-list-item-title>
                    </v-list-item>
                    <v-list-item dense @click="disapproveIngredient(ingredient)">
                      <v-list-item-icon class="mr-2"><v-icon small>mdi-close-circle</v-icon></v-list-item-icon>
                      <v-list-item-title>Disapprove</v-list-item-title>
                    </v-list-item>
                  </v-list>

                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="pa-7" v-if="!ingredients.length && !status.getting">
        <v-alert type="info" border="left" class="mb-0">
          {{ $store.state.user.user && $store.state.user.user.role == 'dietitian' ? "You don't have any custom ingredient yet." : "You don't have any ingredient yet." }}
          {{ $store.state.user.user && $store.state.user.user.role == 'nutritionist' ? "You don't have any custom ingredient yet." : "You don't have any ingredient yet." }}
          {{ $store.state.user.user && $store.state.user.user.role == 'naturopath' ? "You don't have any custom ingredient yet." : "You don't have any ingredient yet." }}
        </v-alert>
      </div>

      <v-card-actions class="blue-grey lighten-5" v-if="activeIngredients.length && !status.getting && showStatus !== 'pending'">
        <v-btn
          v-if="activeIngredients.length >= status.limit"
          @click="getIngredients()"
          :loading="status.loadingMore"
          class="mr-2"
          color="secondary"
          depressed
          small
        >Load More</v-btn>

        <span class="caption">Showing {{ ingredients.length }} items</span>
      </v-card-actions>

      <preloader v-if="status.getting" />
    </v-card>

    <!-- DELETE INGREDIENT DIALOG -->
    <ConfirmDelete
      :showDialog="showDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="cancelDelete()"
      @confirmed="deleteConfirmed()"
    />

    <!-- ADD/EDIT INGREDIENT -->
    <AddIngredientDialog
      :show="showAddIngredient"
      @cancel="hideRecipeDialog()"
    />

  </div>

</template>

<script>

import AddIngredientDialog from '@/components/AddIngredientDialog'
import { mapState, mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Ingredients',

  metaInfo: {
    title: 'Ingredients'
  },

  data() {
    return {
      searchCategory: null,
      toDelete: null,
      showDialog: false,
      deleteMessage: null,
      toEdit: null,
      originalValue: {},
      showStatus: null,
      users: [],
      showRecipeForm: false,
      showAddIngredient: false,
    }
  },

  components: {
    AddIngredientDialog
  },

  computed: {
    ...mapState({
      status: state => state.ingredients.status,
      categories: state => state.categories.categories,
      search: state => state.ingredients.search,
      searchResult: state => state.ingredients.searchResult,
      pendings: state => state.ingredients.pendings,
      members: state => state.members.members,
      user: state => state.user.user,
    }),

    ...mapGetters('ingredients', [
      'activeIngredients'
    ]),

    orderedCategories: function() {
      return _.orderBy(this.categories, ['name'], ['asc'])
    },

    ingredients: function () {
      var ingredients = this.searchResult.length ? this.searchResult : this.activeIngredients

      if (this.searchCategory) {
        ingredients = ingredients.filter(ingredient => {
          return ingredient.category == this.searchCategory
        })
      }

      return this.showStatus == 'pending' ? this.pendings : ingredients
    }
  },

  methods: {
    ...mapActions('ingredients', [
      'getIngredients',
      'addIngredient',
      'deleteIngredient',
      'updateIngredient',
      'refreshList',
      'searchIngredient',
      'getPendings',
      'approveIngredient',
      'disapproveIngredient',
    ]),

    validateForm() {
      if (this.$refs.ingredientForm.validate()) {
        this.addIngredient()
      }
    },

    setToDelete(ingredient) {
      this.toDelete = ingredient
      this.deleteMessage = `Are you sure you want to delete ingrediet "${ingredient.name}"?`
      this.showDialog = true
    },

    cancelDelete() {
      this.toDelete = null
      this.showDialog = false
    },

    deleteConfirmed() {
      Promise.all([this.deleteIngredient(this.toDelete)])
      .then(() => {
        this.cancelDelete()
      })
    },

    setToEdit(ingredient) {
      this.$store.commit('ingredients/setData', ingredient)
      this.showRecipeDialog()
    },

    cancelEdit() {
      let ingredient = this.activeIngredients.find(ingredient => ingredient.id == this.toEdit.id)
      Object.assign(ingredient, this.originalValue)
      this.toEdit = null
    },

    validateUpdateForm() {
      if (this.$refs.updateIngredientForm.validate()) {
        Promise.all([this.updateIngredient(this.toEdit)])
        .then(() => {
          this.toEdit = null
        })
      }
    },

    addRecipeDialog() {
      this.$store.commit('ingredients/setData', {})
      this.showRecipeDialog()
    },

    setStatus(stat) {
      this.showStatus = stat
    },

    showRecipeDialog() {
      this.showAddIngredient = true
    },

    hideRecipeDialog() {
      this.showAddIngredient = false
    },
  },

  created() {
    if (!this.status.firstLoad) this.getIngredients()
    if (!this.$store.state.categories.status.firstLoad) this.$store.dispatch('categories/getCategories')
    if (this.searchResult.length) this.$store.commit('ingredients/clearSearch')

    if (!this.status.firstPendingLoadtLoad) {
      Promise.all([this.getPendings()])
      .then(() => {
        if (this.pendings.length) {
          this.pendings.forEach(item => {
            this.$store.dispatch('members/getMember', item.user)
          })
        }
      })
    }
  },
}

</script>
